@import '../../styles/utils';

.home-hero__title {
    margin: 1rem 1rem 0.5rem;

    color: $white;
    text-align: center;
    text-shadow: 0 4px 4px #000;

    @include media-query-max($breakpoint-small-ubound) {
        min-height: rem-calc(72px);
        margin: $spacing-m $spacing-m $spacing-m/2;
    }
}

.home-hero-background--wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: hidden;

    @include media-query-max($breakpoint-small-ubound) {
        bottom: 40%;
    }

    @include media-query-min(rem-calc(1920px)) {
        bottom: 0;
    }
}

.home-hero-mask--container {
    z-index: 1;
}

.home-hero-mask--apartment {
    $overlay-gradient: linear-gradient(0deg, rgba($black, 0.6) 0%, rgba(217, 217, 217, 0) 100%);
    padding-top: rem-calc(200);
    padding-bottom: rem-calc(40);

    @include media-query-max($breakpoint-small-ubound) {
        z-index: 1;

        padding-top: rem-calc(32);
        padding-bottom: 0;

        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.2);
    }

    @include media-query-min(rem-calc(1920px)) {
        background-size: 100%, cover;
    }

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;

        width: 100%;

        background-image: $overlay-gradient;

        content: ' ';
    }
}

.home-hero__search {
    margin-top: $spacing-m * 2;
    padding-bottom: $spacing-m;

    background: $white;
    border-radius: 8px;

    @include media-query-max($breakpoint-small-ubound) {
        margin-top: $spacing-m;
        padding-bottom: 0;

        border-radius: unset;
    }
}

.home-hero__search [data-reach-tabs] {
    padding: 4px 0 $spacing-l 0;
}

.home-hero__search [data-reach-tab-list],
.home-hero__search [data-reach-tab-panels] {
    padding: 0 $spacing-m 0 $spacing-m;

    @include media-query-max($breakpoint-small-ubound) {
        padding: 0 $spacing-s 0 $spacing-s;
    }
}

.home-hero__tab-panels {
    min-height: 90px;
    @include media-query-max($breakpoint-small-ubound) {
        min-height: 150px;
    }
}

.home-hero__hero-card {
    margin-top: $spacing-l;
    padding: 0 $spacing-s !important;

    box-shadow: unset;

    @include media-query-max($breakpoint-small-ubound) {
        max-width: unset;
        margin: 0;
        margin-top: $spacing-m;
        padding: rem-calc(4px) $spacing-s !important;

        border-radius: unset;
    }
}

.home-grid-container {
    padding: $spacing-xxl 0;
}

.home-data__title {
    margin-bottom: $spacing-l;

    @include media-query-max($breakpoint-small-ubound) {
        margin-bottom: $spacing-m;
    }
}

.home-data__text {
    margin-bottom: $spacing-m;
}

.home-secondary__link {
    margin-right: $spacing-m;
    margin-bottom: $spacing-xl;
}

.home-block--center {
    text-align: center;
}

.home-data--illustration {
    @include media-query-max($breakpoint-small-ubound) {
        margin-top: $spacing-xxl;
    }
}

.home-img__block {
    border-radius: 8px;
}

.home-realtor-card {
    padding: rem-calc(56) $spacing-m;

    @include media-query-max($breakpoint-small-ubound) {
        padding: $spacing-xl $spacing-m rem-calc(48) $spacing-m;
    }
}
